* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

html,
body {
  font: var(--font-body-medium);
  max-width: 100vw;
}

a {
  color: inherit;
  text-decoration: none;
}

#ot-sdk-btn-floating {
  display: none !important;
}

.spinner {
  animation: rotation 1s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
